.react-multi-email {
    margin: 0;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    padding: 0.28em 0.5em !important;
    border: 1px solid rgba(0, 0, 0, 0.23);
    color: rgba(0, 0, 0, 0.87) !important;
    border-radius: 0.48571429rem !important;
    -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
}

.react-multi-email>span[data-placeholder] {
    display: none;
    position: absolute;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 400 !important;
    left: 0.5em;
    top: 0.4em;
    padding: 0.28em !important;
    line-height: 1.21428571em;
}

.react-multi-email.focused {
    border-color: #1976d2;
    border-width: 2px;
}

.react-multi-email.empty>span[data-placeholder] {
    display: inline;
    color: #ccc;
}

.react-multi-email.focused>span[data-placeholder] {
    display: none;
}

.react-multi-email>input {
    flex: 1;
    width: auto !important;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    vertical-align: baseline !important;
    padding: 0.28em 0.1em !important;
}

.react-multi-email [data-tag] {
    line-height: 1.5;
    vertical-align: baseline;
    margin: 0.14285714em;
    background-image: none;
    padding: 0.28em 0.8em !important;
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    text-transform: none;
    border: 0 solid transparent;
    border-radius: 8rem !important;
    -webkit-transition: background 0.1s ease;
    -o-transition: background 0.1s ease;
    transition: background 0.1s ease;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
    max-width: 100%;
    overflow: hidden;
}

.react-multi-email [data-tag]:first-child {
    margin-left: 0;
}

.react-multi-email [data-tag] [data-tag-handle] {
    margin-left: 0.833em;
    cursor: pointer;
}